import React, { useState, useEffect, CSSProperties } from 'react'
import { Print, Clothing, Merch } from '../constants/products'
import axios from 'axios'
import Loading from '../components/common/Loading'
import { galleryPageCopy } from '../constants/galleryPageCopy'
import VacationMode from '../components/gallery/VacationMode'
import GalleryProduct from '../components/gallery/GalleryProduct'
import {
  FONTS,
  COLORS,
  FONT_WEIGHTS,
  FONT_SIZES,
  LINE_HEIGHTS
} from '../constants/theme'
import useViewportType from '../utils/useViewportType'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { getRandomDoodles } from '../utils/getRandomDoodles'

const styles: { [key: string]: CSSProperties | any } = {
  galleryWrapper: {
    width: '80%',
    margin: 'auto'
  },
  headerWrapper: (isMobile: boolean) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between'
  }),
  header: (isMobile: boolean) => ({
    fontFamily: FONTS.serif,
    color: COLORS.darkBlue,
    fontWeight: FONT_WEIGHTS.medium,
    fontSize: isMobile ? FONT_SIZES.mobileHeader : FONT_SIZES.desktopHeader,
    lineHeight: isMobile
      ? LINE_HEIGHTS.mobileHeader
      : LINE_HEIGHTS.desktopHeader,
    marginBottom: isMobile ? '20px' : '40px'
  }),
  subheader: (isMobile: boolean) => ({
    fontFamily: FONTS.sansSerif,
    color: COLORS.darkBlue,
    fontWeight: FONT_WEIGHTS.mediumLight,
    fontSize: isMobile ? FONT_SIZES.mobileBody : FONT_SIZES.desktopBody,
    lineHeight: isMobile ? LINE_HEIGHTS.mobileBody : LINE_HEIGHTS.desktopBody,
    marginBottom: isMobile ? '20px' : '40px',
    maxWidth: '600px'
  }),
  parallaxDoodle: (doodleLocation: string, isMobile: boolean) => ({
    width: isMobile ? '150px' : '400px',
    height: '250px',
    backgroundImage: `url(${doodleLocation})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    zIndex: -1
  }),
  galleryContent: (isMobile: boolean, isWidescreen: boolean) => ({
    display: 'grid',
    gridTemplateColumns: isMobile
      ? '1fr'
      : isWidescreen
      ? 'repeat(4, 1fr)'
      : 'repeat(2, 1fr)',
    gap: '16px',
    padding: isMobile ? '0px' : '16px'
  })
}

const Gallery = ({ productType }) => {
  const [products, setProducts] = useState<Print[] | Clothing[] | Merch[]>([])
  // const [vacationMode, setVacationMode] = useState<{
  //   vacationModeOn: boolean
  //   message?: string
  // }>({ vacationModeOn: false })
  const [isLoading, setIsLoading] = useState(true)

  const isMobile = useViewportType(900)
  const isWidescreen = !useViewportType(1750)

  const header = productType.charAt(0).toUpperCase() + productType.slice(1)
  const copy = galleryPageCopy[productType.toUpperCase()]

  const doodles = getRandomDoodles()

  const getProducts = async () => {
    const productRes = await axios.get(`/api/${productType}`)

    if (productRes.status === 200 && productRes.data) {
      setProducts(productRes.data)
    }

    setIsLoading(false)
  }

  // const getVacationMode = async () => {
  //   const vacationModeRes = await axios.get('/api/vacation-mode')

  //   if (vacationModeRes.status === 200 && vacationModeRes.data) {
  //     setVacationMode({
  //       vacationModeOn: vacationModeRes.data.vacationModeOn,
  //       message: vacationModeRes.data.message
  //     })
  //   }
  // }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    // if (productType === 'clothing' || productType === 'merch') {
    //   getVacationMode()
    // }

    getProducts()
  }, [])

  return (
    <ParallaxProvider>
      <div style={styles.galleryWrapper}>
        <div style={styles.headerWrapper(isMobile)}>
          <div style={styles.textWrapper}>
            <h1 style={styles.header(isMobile)}>{header}</h1>
            {copy.map((text: string) => (
              <h3 style={styles.subheader(isMobile)}>{text}</h3>
            ))}
          </div>

          {!isMobile && (
            <Parallax speed={10} style={{ position: 'relative' }}>
              <div style={styles.parallaxDoodle(doodles[0], false)}></div>
            </Parallax>
          )}
        </div>

        <div style={styles.galleryContent(isMobile, isWidescreen)}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {
              // vacationMode.vacationModeOn ? (
              //   <VacationMode />
              // ) : (
                products.map((product: Print | Clothing | Merch) => (
                  <GalleryProduct
                    product={product}
                    isMobile={isMobile}
                    productType={productType}
                  />
                ))
              // )
              }
            </>
          )}
        </div>

        <Parallax speed={10} style={{ position: 'relative' }}>
          <div style={styles.parallaxDoodle(doodles[1], isMobile)}></div>
        </Parallax>
      </div>
    </ParallaxProvider>
  )
}

export default Gallery
