import React, { CSSProperties } from 'react'
import { COLORS, FONTS, FONT_WEIGHTS } from '../../constants/theme'
import ReadMore from './ReadMore'
import useViewportType from '../../utils/useViewportType'

const styles: { [key: string]: CSSProperties } | any = {
  aboutWrapper: (isMobile: boolean) => ({
    margin: 'auto',
    marginTop: '48px',
    width: isMobile ? '87.5vw' : '80vw'
  }),
  copy: (isMobile: boolean) => ({
    fontFamily: FONTS.serif,
    fontWeight: FONT_WEIGHTS.light,
    color: COLORS.medBlue,
    fontSize: isMobile ? '24px' : '40px',
    lineHeight: isMobile ? '28px' : '44px',
    width: isMobile ? '87.5vw' : '70.5vw',
    position: 'relative',
    zIndex: 1
  })
}

interface Props {
  doodle: string
}

const AboutSection = ({ doodle }: Props) => {
  const isMobile = useViewportType(700)

  return (
    <section style={styles.aboutWrapper(isMobile)}>
      <h2 style={styles.copy(isMobile)}>
        Enchanted Quill Artwork is an art studio that offers a collection of
        whimsical art pieces, hand-drawn by Rebecca Stone. Experience
        fantastical creatures that take you on a journey into a dream-like
        world.
      </h2>

      <ReadMore doodleLocation={doodle} buttonUri='/about' />
    </section>
  )
}

export default AboutSection
