export const galleryPageCopy = {
  PRINTS: ['Prints of original artwork by Rebecca Stone, the creator of Enchanted Quill Artwork.'],
  CLOTHING: [
    'All clothing is printed by hand using unique designs and print placements. I screen print, heat press, relief print, and cyanotype for a range of textures and aesthetics.',
    'Like you, it\'s the only one in existence.'
  ],
  MERCH: [
    'Small ways to carry one of a kind artwork with you, everywhere you go.',
    'Stickers are shipped in standard postage envelopes through USPS, so they may have a longer ship time.'
  ]
}
