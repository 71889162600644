export const BREAKPOINTS = {}

export const COLORS = {
  darkBlue: '#002c50',
  medBlue: '#00417e',
  offWhite: '#e6e9e0',
  orange: '#e85a22',
  success: '#41F2A0',
  yellow: '#f2cd3d'
}

export const FONTS = {
  serif: 'Averia Serif Libre, cursive',
  sansSerif: 'Roboto, sans-serif'
}

export const FONT_WEIGHTS = {
  light: 300,
  mediumLight: 400,
  medium: 500,
  heavy: 700
}

export const FONT_SIZES = {
  desktopHeader: '64px',
  desktopBody: '24px',
  desktopSubBody: '16px',
  mobileHeader: '32px',
  mobileBody: '18px'
}

export const LINE_HEIGHTS = {
  desktopHeader: '72px',
  desktopBody: '32px',
  mobileHeader: '36px',
  mobileBody: '24px'
}

