import React, { CSSProperties } from 'react'
import PropTypes from 'prop-types'
import NavDesktop from './nav/NavDesktop'
import NavMobile from './nav/NavMobile'
import Footer from './footer/Footer'
import { COLORS } from '../../constants/theme'
import useViewportType from '../../utils/useViewportType'
import { useLocation } from 'react-router' // TODO: REMOVE AS SOON AS OTHER PAGES ARE DONE

interface Props {
  children: React.ReactNode
}

const PageWrapper = ({ children }: Props): React.ReactElement => {
  const isMobile = useViewportType(900)

  const location = useLocation() // TODO: REMOVE AS SOON AS OTHER PAGES ARE DONE

  const styles: { [key: string]: CSSProperties } = {
    wrapper: {
      backgroundImage: 'url("../../assets/images/wall_texture.svg")',
      backgroundRepeat: 'repeat',
      backgroundColor: COLORS.offWhite,
      height: '100%',
      overflowX: 'hidden'
    }
  }

  return (
    <div style={styles.wrapper}>
      {isMobile ? <NavMobile /> : <NavDesktop />}

      {/* TODO: REMOVE AS SOON AS OTHER PAGES ARE COMPLETE */}
      <div style={{ height: (location.pathname === '/' || location.pathname === '/home') ? '0' : '50px' }}></div>

      {children}
      <Footer />
    </div>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.element || PropTypes.arrayOf(PropTypes.element)
}

export default PageWrapper
