import React, { CSSProperties } from 'react'
import { priceFormatter } from '../../utils/priceFormatter'
import { useHistory } from 'react-router-dom'
import { COLORS, FONT_WEIGHTS, FONTS } from '../../constants/theme'

const styles: { [key: string]: CSSProperties | any } = {
  productWrapper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  image: (productImage: string) => ({
    backgroundImage: `url(${productImage})`,
    aspectRatio: '1 / 1',
    backgroundColor: COLORS.darkBlue,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
  printName: {
    fontFamily: FONTS.serif,
    fontWeight: FONT_WEIGHTS.medium,
    fontSize: '1em',
    marginTop: '1em',
    marginBottom: '.325em',
    color: COLORS.darkBlue
  },
  descName: {
    fontFamily: FONTS.sansSerif,
    fontWeight: FONT_WEIGHTS.mediumLight,
    fontSize: '.75em',
    lineHeight: '1em',
    marginBottom: '.325em',
    color: COLORS.darkBlue
  },
  price: (isPrint: boolean) => ({
    fontFamily: FONTS.sansSerif,
    fontSize: '.75em',
    lineHeight: '1em',
    color: COLORS.darkBlue,
    fontWeight: isPrint ? FONT_WEIGHTS.mediumLight : FONT_WEIGHTS.medium
  })
}

const GalleryProduct = ({ product, productType, isMobile }) => {
  const history = useHistory()
  const productImage = product.location ?? product.primaryImage
  const productPrice = productType === 'prints'
    ? `From ${priceFormatter.format(product.smallPrice)}`
    : priceFormatter.format(product.price)

  return (
    <div
      style={styles.productWrapper}
      onClick={() =>
        history.push(
          `${productType === 'prints' ? 'image' : productType}/${product.id}`
        )
      }
    >
      <div style={styles.image(productImage)}></div>

      <h2 style={styles.printName}>{product.name ?? product.printName}</h2>
      {productType !== 'prints' && (
        <h3 style={styles.descName}>{product.descName}</h3>
      )}

      <h3 style={styles.price(productType === 'prints')}>{productPrice}</h3>
    </div>
  )
}

export default GalleryProduct
